$CM-brand-naranja: #F38F00;
$CM-brand-naranja-disabled: #c07100;

tr.ant-table-row.ant-table-row-level-0 {
  &.selected {
    background-color: $CM-brand-naranja;
  }

  &:hover {
    background-color: rgba($CM-brand-naranja-disabled, 0.5);
    > td {
      background-color: rgba($CM-brand-naranja-disabled, 0.5);
    }
  }
}

td.ant-table-column-sort {
  background: none;
}
